import { defineStore } from 'pinia';
import { ref } from 'vue';
import IosPatchSystemApi from '@/services/iosPatchSystemApi';
import { Shortcut, Hashtag } from '@/types/hashtags';
import presentationServiceApi from '@/services/presentationServiceApi';
import AccountsStore from '@/store/accounts';
import { presServiceCountries } from '@/rs-publish/consts/presServiceCountries';

export const useHashtagsStore = defineStore('Hashtags Service', () => {
  // Initial State
  const shortcuts = ref<Array<Shortcut>>([]);
  const psHashtags = ref<Array<Hashtag>>([]);
  const hashtagInput = ref<string[]>([]);
  const overrideDate = ref<number | null>(null);

  const setDebugDate = (date: number | string | null) => {
    overrideDate.value = date ? Number(date) : null;
  };

  const fetchPsHashtags = async (activeFlags: string, countryFF: string) => {
    const countryCode = ref('');
    const regionsResult = await setHashtagsRegions();
    countryCode.value = regionsResult ? regionsResult : countryFF;

    const today = new Date();
    const body = {
      country_code: countryCode.value,
      date: today.toISOString(),
      version: '1.0',
    };
    const result = await presentationServiceApi.getHashtags(body, activeFlags);
    psHashtags.value = result.modules.map((hashtag) => {
      return {
        hashtagText: hashtag.options.key,
        hashtagType: hashtag.options.type,
      };
    });
  };

  const fetchHashtags = async () => {
    const configFile = await IosPatchSystemApi.getConfig();
    shortcuts.value = configFile.shortcuts;
  };

  const getActiveHashtags = async () => {
    const configFile = await IosPatchSystemApi.getConfig();
    const correctTypeArray = configFile.shortcuts.filter((shortcut) => shortcut.shortcutType === 2);
    shortcuts.value = correctTypeArray.filter((hashtag) => {
      const today = new Date().getTime();
      const activeDate = overrideDate.value ?? today;
      if (hashtag.startDate && hashtag.endDate) {
        const start = new Date(hashtag.startDate);
        const end = new Date(hashtag.endDate);
        return activeDate > start.getTime() && activeDate < end.getTime();
      } else if (hashtag.startDate) {
        const start = new Date(hashtag.startDate);
        return activeDate > start.getTime();
      } else if (hashtag.endDate) {
        const end = new Date(hashtag.endDate);
        return activeDate < end.getTime();
      }
      return true;
    });
  };

  const setHashtagInput = (hashtags: string[]) => {
    hashtagInput.value = hashtags;
  };

  async function setHashtagsRegions() {
    const userCountry = AccountsStore.account.regions_of_influence
      .map((country) => {
        switch (country) {
          case 'GB':
            return 'uk';
          default:
            return country.toLowerCase();
        }
      })
      .filter((country) => presServiceCountries.find(({ code }) => code === country) !== undefined);
    // above makes sure to only return countries that are approved for presentation service
    return userCountry[0];
  }

  function $reset() {
    hashtagInput.value = [];
    shortcuts.value = [];
    psHashtags.value = [];
  }

  return {
    hashtagInput,
    overrideDate,
    psHashtags,
    shortcuts,
    fetchHashtags,
    fetchPsHashtags,
    getActiveHashtags,
    setDebugDate,
    setHashtagInput,
    setHashtagsRegions,
    $reset,
  };
});
