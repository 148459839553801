import Vue from 'vue';
import { Module, VuexModule, Action, Mutation, getModule } from 'vuex-module-decorators';
import store from '@/store';
import AccountsApi from '@/store/accounts/api';
import { UserRoles, UserLocale } from '@/rs-account/constants';
import {
  AccountStoreState,
  Account,
  AccountUser,
  TermsResponse,
  TermsBody,
  PostAcceptTermsUser,
  InitResponse,
} from '@/store/accounts/types';
import { Currency } from '@/utils/currency';
import { ApiErrorResponse, ApiErrorReturn } from '@/utils/api/types';
import contentTypeCheck, { ContentType } from '@/store/accounts/accountContentTypes';
import removeDuplicateStringsInArray from '@/utils/removeDuplicateStringsInArray';
import SigilApi from '@/rs-account/services/SigilApi';
import UserInformation from '@/store/userInfo/index';
import { decodeUserStatus } from '@/utils/decodeUserStatus';
import Cookie from 'js-cookie';
import { ApiError, isProcessResponseError, ProcessResponseError } from '@/utils/api';

@Module({ dynamic: true, name: 'AccountsStore', namespaced: true, store })
class AccountsStoreModule extends VuexModule implements AccountStoreState {
  public account: Account = {
    id: '',
    owner_id: '',
    company: {
      name: '',
      blog: '',
      url: '',
    },
    display_currency: Currency.USD,
    payment: { email: '' },
    publishing_platforms: [],
    regions_of_influence: [],
    content_types: [],
    address: {
      street: '',
      postal: '',
      city: '',
      state: '',
      country: '',
    },
  };

  public user: AccountUser = {
    id: '',
    shopper_regions: [],
    account_id: '',
    address: {
      street: '',
      postal: '',
      city: '',
      state: '',
      country: '',
    },
    contact: {
      phone: '',
      country: 'us' as IntlTelInputIso2CountryCode,
      is_subscribed_broadcast: false,
    },
    shortlink: '',
    role: UserRoles.PRODUCT_SEARCH_ONLY_NO_COMMISSIONS,
    enabled: false,
    name: '',
    email: '',
    phone_number: '', // @deprecated
    opt_into_sms: false, // @deprecated
    locale: (Cookie.get('rs_lang') as UserLocale) || UserLocale.en_US,
    status: 256, // This should default to `UserStatus.REGISTERED` (256) to prevent unauthorized access to the application.
    is_owner: false,
    needs_accept_terms: false,
    is_blacklisted: false,
  };

  public users: AccountUser[] = [];
  public allUsers: AccountUser[] = [];
  public acceptTermsData: TermsResponse | null = null;

  public doesHaveAccountUser = false;

  public hasCheckedAccountUser = false;
  public hasFetchedAccountUser = false;
  public hasFetchedAccount = false;
  public hasFetchedAccountUsers = false;

  // Error Objects
  public initError: ProcessResponseError | null = null;
  public accountError: ApiErrorResponse | null = null;
  public userError: ProcessResponseError | null = null;
  public usersListError: ApiErrorResponse | null = null;
  public changeEmailError: ApiErrorResponse | null = null;
  public acceptTermsError: ApiErrorResponse | null = null;
  public statusCompleteError: ApiErrorResponse | null = null;

  public get isUserAdmin() {
    return this.user?.role?.toUpperCase() === UserRoles.ADMIN || false;
  }

  public get userStatusRoles() {
    return decodeUserStatus(this.user.status);
  }

  /**
   * @description Initialize the Account and User data. This action creates a new Account and User ID for the user.
   */
  @Action
  public async init() {
    try {
      const response = await AccountsApi.init();
      this.GET_INIT(response);
      this.HANDLE_INIT_ERROR(null);
    } catch (error) {
      if (isProcessResponseError(error)) {
        this.HANDLE_INIT_ERROR(error);
      } else {
        throw error;
      }
    }
  }
  /**
   * @description Checks if the account info is available
   * @param {Object} options - Options object
   * @param {boolean} options.overwrite - Determines whether the action fetches and overwrites the data. Set to false (cache data from first action call) by default.
   */
  @Action
  public async fetchAccount({ overwrite = false }) {
    const canOverwrite = overwrite || !this.hasFetchedAccount;
    try {
      if (canOverwrite) {
        const response = await AccountsApi.getAccount();
        this.GET_ACCOUNT(response.account);
        this.HANDLE_ACCOUNT_ERROR(null);
        this.HAS_FETCHED_ACCOUNT(true);
      }
    } catch (error) {
      this.HANDLE_ACCOUNT_ERROR(error as ApiErrorReturn);
    }
  }

  /**
   * @description Checks if the account user info is available
   * @param {Object} options - Options object
   * @param {boolean} options.overwrite - Determines whether the action fetches and overwrites the data. Set to false (cache data from first action call) by default.
   */
  @Action
  public async hasAccountUser({ overwrite = false }): Promise<boolean> {
    const canOverwrite = overwrite || !this.hasCheckedAccountUser;
    let result: boolean = this.doesHaveAccountUser;

    try {
      if (canOverwrite) {
        await AccountsApi.getUser();
        result = true;
      }
    } catch (error) {
      result = false;
    } finally {
      this.HAS_CHECKED_ACCOUNT_USER(true);
      this.DOES_HAVE_ACCOUNT_USER(result);
    }

    return result;
  }

  /**
   * @description Fetch account user info.
   *
   * Receives a `403 Forbidden` error response if the user has not initialized their Account and User data with `init()`.
   *
   * @param {Object} options - Options object
   * @param {boolean} options.overwrite - Determines whether the action fetches and overwrites the data. Set to false (cache data from first action call) by default.
   */
  @Action
  public async fetchAccountUser({ overwrite = false }) {
    const canOverwrite = overwrite || !this.hasFetchedAccountUser;

    try {
      if (canOverwrite) {
        const response = await AccountsApi.getUser();
        this.GET_ACCOUNT_USER(response.user);
        this.HANDLE_USER_ERROR(null);
        this.HAS_FETCHED_ACCOUNT_USER(true);
      }
    } catch (error) {
      this.HANDLE_USER_ERROR(error as ProcessResponseError);
    }
  }

  @Action
  public async fetchAccountUsers() {
    try {
      const response = await AccountsApi.getAccountUsers();
      this.GET_ACCOUNT_USERS(response.users);
      this.HANDLE_USERS_ERROR(null);
      this.HAS_FETCHED_ACCOUNT_USERS(true);
    } catch (error) {
      this.HANDLE_USERS_ERROR(error as ApiErrorReturn);
    }
  }

  @Action
  public async patchAccount(updatedAccount: Partial<Account>) {
    try {
      const response = await AccountsApi.patchAccount(updatedAccount);
      this.PATCH_ACCOUNT(response.account);
      this.HANDLE_ACCOUNT_ERROR(null);
    } catch (error) {
      this.HANDLE_ACCOUNT_ERROR(error as ApiErrorReturn);
    }
  }

  @Action
  public async patchUser(updatedUser: Partial<AccountUser>) {
    try {
      // Backend doesn't want to see deprecated fields if the new ones are being used.
      const { phone_number, opt_into_sms, ...cleanedUpdatedUser } = updatedUser;
      const response = await AccountsApi.patchUser(cleanedUpdatedUser, updatedUser.id?.toString() || this.user.id);
      this.PATCH_ACCOUNT_USER(response.user);
      this.HANDLE_USER_ERROR(null);
    } catch (error) {
      this.HANDLE_USER_ERROR(error as ApiErrorReturn);
    }
  }

  @Action
  public async addNewUser(user: Partial<AccountUser>) {
    try {
      const newUser = {
        ...user,
        shortlink: this.user.shortlink,
        locale: this.user.locale,
      };
      const response = await AccountsApi.postAccountUser(newUser);
      this.ADD_NEW_USER(response.user);
      this.HANDLE_USER_ERROR(null);
    } catch (error) {
      this.HANDLE_USER_ERROR(error as ApiErrorReturn);
    }
  }

  @Action
  public async changeUserEmail(token: string) {
    try {
      const changeEmailResponse = await AccountsApi.changeEmail(token);
      await SigilApi.changeUserEmail(changeEmailResponse.confirmation.email);
      const confirmEmailChangeResponse = await AccountsApi.confirmChangeEmail(token);

      this.CHANGE_USER_EMAIL(confirmEmailChangeResponse.confirmation.email);
      this.HANDLE_CHANGE_EMAIL_ERROR(null);

      // Will fetch updated user email in get_user_info api
      await UserInformation.fetchUserInfo({ overwrite: true });
    } catch (error) {
      this.HANDLE_CHANGE_EMAIL_ERROR(error as ApiErrorReturn);
    } finally {
      // Clear token query param from url
      this.removeTokenFromURL();
    }
  }

  @Action
  public async changePaymentEmail(token: string) {
    try {
      const response = await AccountsApi.confirmChangeEmail(token);
      this.CHANGE_PAYMENT_EMAIL(response.confirmation.email);
      this.HANDLE_CHANGE_EMAIL_ERROR(null);
    } catch (error) {
      this.HANDLE_CHANGE_EMAIL_ERROR(error as ApiErrorReturn);
    } finally {
      // Clear token query param from url
      this.removeTokenFromURL();
    }
  }

  @Action
  private removeTokenFromURL() {
    window.history.pushState(
      {},
      document.title,
      window.location.toString().substring(0, window.location.toString().indexOf('?')),
    );
  }

  @Action
  public async fetchTermsData() {
    try {
      const response = await AccountsApi.getAcceptTerms();
      this.GET_ACCEPT_TERMS_DATA(response);
      this.HANDLE_ACCEPT_TERMS_ERROR(null);
    } catch (error) {
      this.HANDLE_ACCEPT_TERMS_ERROR(error as ApiErrorReturn);
    }
  }

  @Action
  public async postTermsData(userData: PostAcceptTermsUser) {
    try {
      const body: TermsBody = {
        terms_id: this.acceptTermsData?.terms.id || '',
        first_name: userData.firstName,
        last_name: userData.lastName,
      };

      const response = await AccountsApi.postAcceptTerms(body);

      this.GET_ACCEPT_TERMS_DATA(response);
      this.HANDLE_ACCEPT_TERMS_ERROR(null);
    } catch (error) {
      this.HANDLE_ACCEPT_TERMS_ERROR(error as ApiErrorReturn);
    }
  }

  @Action
  public async putStatusComplete() {
    try {
      const response = await AccountsApi.putStatusComplete();
      this.HANDLE_STATUS_COMPLETE_ERROR(null);
      this.PATCH_ACCOUNT_USER(response.user);
    } catch (error) {
      this.HANDLE_STATUS_COMPLETE_ERROR(error as ApiErrorReturn);
    }
  }

  @Mutation
  public GET_INIT({ account, user }: InitResponse) {
    // Update exisiting Account and User properties
    this.account = { ...this.account, ...account };
    this.user = { ...this.user, ...user };
  }

  @Mutation
  public GET_ACCOUNT(account: Account) {
    account.content_types = contentTypeCheck(account?.content_types as ContentType[]);
    account.publishing_platforms = removeDuplicateStringsInArray(account?.publishing_platforms);
    account.regions_of_influence = removeDuplicateStringsInArray(account?.regions_of_influence);

    Vue.set(this, 'account', account);
  }

  @Mutation
  public DOES_HAVE_ACCOUNT_USER(hasAccountUser: boolean) {
    this.doesHaveAccountUser = hasAccountUser;
  }

  @Mutation
  public GET_ACCOUNT_USER(user: AccountUser) {
    Vue.set(this, 'user', user);
  }

  @Mutation
  public GET_ACCOUNT_USERS(users: AccountUser[]) {
    Vue.set(this, 'allUsers', users);

    // filteredList filters out account owner
    const filteredList = users.filter((user: AccountUser) => {
      if (this.account.owner_id && user.id.toString() !== this.account.owner_id.toString()) {
        return user;
      }
    });

    Vue.set(this, 'users', filteredList);
  }

  @Mutation
  public PATCH_ACCOUNT(account: Account) {
    Vue.set(this, 'account', account);
  }

  @Mutation
  public PATCH_ACCOUNT_USER(updatedUser: AccountUser) {
    // Update current user if ids match
    if (updatedUser.id.toString() === this.user.id.toString()) {
      Vue.set(this, 'user', updatedUser);
    }

    // Update user in list
    if (this.users) {
      const newUserList = this.users;
      const updateIndex = this.users?.findIndex((user) => user.id.toString() === updatedUser.id.toString());

      if (typeof updateIndex !== 'undefined' && updateIndex !== -1) {
        newUserList[updateIndex] = updatedUser;
        Vue.set(this, 'users', newUserList);
      }
    }
  }

  @Mutation
  public ADD_NEW_USER(updatedUser: AccountUser) {
    const newUserList = this.users as AccountUser[];
    if (newUserList) {
      newUserList.push(updatedUser);
      Vue.set(this, 'users', newUserList);
    }
  }

  @Mutation
  public CHANGE_USER_EMAIL(email: string) {
    Vue.set(this.user, 'email', email);
  }

  @Mutation
  public CHANGE_PAYMENT_EMAIL(email: string) {
    this.account.payment = Object.assign({}, this.account.payment, { email });
  }

  @Mutation
  public GET_ACCEPT_TERMS_DATA(acceptTerms: TermsResponse) {
    Vue.set(this, 'acceptTermsData', acceptTerms);
  }

  @Mutation
  public HAS_CHECKED_ACCOUNT_USER(hasCheckedAccountUser: boolean) {
    this.hasCheckedAccountUser = hasCheckedAccountUser;
  }

  @Mutation
  public HAS_FETCHED_ACCOUNT_USER(hasFetchedAccountUser: boolean) {
    this.hasFetchedAccountUser = hasFetchedAccountUser;
  }

  @Mutation
  public HAS_FETCHED_ACCOUNT_USERS(hasFetchedAccountUsers: boolean) {
    this.hasFetchedAccountUsers = hasFetchedAccountUsers;
  }

  @Mutation
  public HAS_FETCHED_ACCOUNT(hasFetchedAccount: boolean) {
    this.hasFetchedAccount = hasFetchedAccount;
  }

  @Mutation
  public HANDLE_INIT_ERROR(error: ProcessResponseError | null) {
    this.initError = error;
  }

  @Mutation
  public HANDLE_ACCOUNT_ERROR(error: ApiErrorReturn | null) {
    Vue.set(this, 'accountError', error ? error.response : null);
  }

  @Mutation
  public HANDLE_USER_ERROR(error: unknown | null) {
    // Reset User Error
    if (error === null) {
      this.userError = null;
      return;
    }

    // Unwrap User Error
    const unwrapped: ProcessResponseError | null =
      ApiError.fromApiErrorReturn(error) ?? (isProcessResponseError(error) ? error : null);

    // Re-throw if it's not an Error (who sent us this garbage?)
    if (!unwrapped) {
      throw unwrapped;
    }

    this.userError = unwrapped;
  }

  @Mutation
  public HANDLE_USERS_ERROR(error: ApiErrorReturn | null) {
    Vue.set(this, 'usersListError', error ? error.response : null);
  }

  @Mutation
  public HANDLE_CHANGE_EMAIL_ERROR(error: ApiErrorReturn | null) {
    Vue.set(this, 'changeEmailError', error ? error.response : null);
  }

  @Mutation
  public HANDLE_ACCEPT_TERMS_ERROR(error: ApiErrorReturn | null) {
    Vue.set(this, 'acceptTermsError', error ? error.response : null);
  }

  @Mutation
  public HANDLE_STATUS_COMPLETE_ERROR(error: ApiErrorReturn | null) {
    Vue.set(this, 'statusCompleteError', error ? error.response : null);
  }
}

export default getModule(AccountsStoreModule);
