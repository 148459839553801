import { render, staticRenderFns } from "./RwModalHeader.vue?vue&type=template&id=00532440&"
import script from "./RwModalHeader.vue?vue&type=script&lang=ts&"
export * from "./RwModalHeader.vue?vue&type=script&lang=ts&"
import style0 from "./RwModalHeader.vue?vue&type=style&index=0&id=00532440&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports