import { createPopper, Placement } from '@popperjs/core';
import { Ref, computed, nextTick, onMounted, onUnmounted, onUpdated, reactive, ref, watch, watchEffect } from 'vue';
import useKeyboardFocus from './useKeyboardFocus';

export interface PopperProps {
  modelValue: boolean;
  theme: string;
  placement: Placement;
  offset: number[];
  auto: boolean;
  target: string;
  portalDisabled: boolean;
  focusable: boolean;
  escapeClose: boolean;
  outsideClick: boolean;
  hideArrow?: boolean;
  delay: number;
  textAlign?: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent' | 'unset';
}

const usePopper = (props: PopperProps, emit: any, mode = 'tooltip') => {
  const targetRef = ref(null) as Ref<any | null>;
  const contentRef = ref(null) as Ref<HTMLElement | null>;
  const parentRef = ref(null) as Ref<HTMLElement | null>;
  const popper = reactive({
    instance: null,
    target: null,
    content: null,
  }) as {
    instance: any | null;
    target: any | null;
    content: any | null;
  };
  const shouldRender = ref(false);
  const hasFocus = useKeyboardFocus(parentRef);

  // Popper Options
  const options = computed(() => {
    return {
      placement: props.placement,
      modifiers: [
        {
          name: 'computeStyles',
          options: {
            gpuAcceleration: false,
            adaptive: false,
          },
        },
        {
          name: 'offset',
          options: {
            offset: props.offset,
          },
        },
        {
          name: 'preventOverflow',
          options: {
            boundary: 'document',
            rootBoundary: 'viewport',
            padding: 8,
          },
        },
        {
          name: 'flip',
          options: {
            padding: 8,
            altBoundary: false,
            fallbackPlacements: ['top', 'bottom'],
          },
        },
        {
          name: 'arrow',
          options: {
            padding: 16,
          },
        },
      ],
    };
  });

  // create popper
  const makePopper = () => {
    if (popper.target instanceof HTMLElement) {
      popper.instance = createPopper(popper.target, popper.content, options.value);
    }
  };
  // kill your creation
  const killPopper = () => {
    if (popper.instance) {
      popper.instance.destroy();
      popper.instance = null;
    }
  };

  // bind popper instance at the next repaint
  const bindPopper = () => {
    nextTick().then(() => {
      if (popper.target) makePopper();
    });
  };

  const resetPopper = () => {
    if (popper.instance) {
      killPopper();
      makePopper();
    }
  };

  const show = () => {
    popper.target = targetRef.value;
    shouldRender.value = true;
    setTimeout(
      () => {
        nextTick().then(() => {
          emit('opened');
          emit('update:modelValue', shouldRender.value);
        });
      },
      (props.delay as number) || 0,
    );
  };

  const hide = () => {
    shouldRender.value = false;
    emit('closed');
    emit('update:modelValue', false);
  };

  // In some cases the direct parent is not the node that we want to bind the event listener to
  // This may need to be expanded on to support more use cases as they come up.
  // This is mostly relavent to elements which have a deep dom structure and are focusable.

  const setTargetEl = () => {
    const parentNode = targetRef.value?.$el.parentNode;
    const grandParentNode = targetRef.value?.$el.parentNode.parentNode;
    const grandparentClasses = grandParentNode?.classList.value;
    if (grandparentClasses?.includes('rw--button') && mode === 'tooltip') return grandParentNode;

    return parentNode;
  };

  const mountEventBindings = async () => {
    await nextTick();
    parentRef.value = setTargetEl();
    if (parentRef.value instanceof HTMLElement) {
      popper.target = parentRef.value;
      if (props.auto && (popper.target || mode === 'tooltip')) {
        popper.target.addEventListener('mouseenter', show, { passive: true });
        popper.target.addEventListener('mouseleave', hide, { passive: true });
      }
    }
  };

  watchEffect(() => {
    if (props.modelValue || (hasFocus.value && mode === 'tooltip')) {
      show();
    } else {
      hide();
    }
  });

  watch(
    () => shouldRender.value,
    (next) => {
      if (next) emit('update:modelValue', next);
    },
  );

  onMounted(() => {
    mountEventBindings();
    resetPopper();
    if (shouldRender.value) {
      nextTick().then(() => {
        popper.content = contentRef.value;
        bindPopper();
      });
    }
  });

  // This runs in the onUpdated lifecycle since the
  // contentRef is not available until after the component is rendered
  // and shouldRender.value returns true
  // This is not required in Vue3

  onUpdated(() => {
    mountEventBindings();
    resetPopper();
    if (shouldRender.value) {
      nextTick().then(() => {
        popper.content = contentRef.value;
        bindPopper();
      });
    }
  });

  onUnmounted(() => {
    if ((props.auto || mode === 'tooltip') && popper.target) {
      killPopper();
      popper.target.removeEventListener('mouseenter', show);
      popper.target.removeEventListener('mouseleave', hide);
    }
  });
  return { contentRef, targetRef, shouldRender };
};

export default usePopper;
