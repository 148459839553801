
import { defineComponent, computed } from 'vue';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'RwModalHeader',
  props: {
    centered: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    title: {
      type: String as PropType<string>,
      default: '',
    },
    subtitle: {
      type: String as PropType<string>,
      default: '',
    },
    parentheticalTitle: {
      type: String as PropType<string>,
      default: '',
    },
    padding: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    bordered: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    customClass: {
      type: String as PropType<string>,
      default: '',
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const onClick = () => {
      emit('close');
    };
    const modifiers = computed(() => {
      return {
        'is--padded': props.padding,
        'is--centered': props.centered,
        'is--bordered': props.bordered,
      };
    });
    return { onClick, modifiers };
  },
});
