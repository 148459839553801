import AccountsStore from '@/store/accounts';
import UserInfoStore from '@/store/userInfo';
import ProfilesStore from '@/store/profiles';

type FetchUserDataOptions = {
  overwrite?:
    | boolean
    | {
        fetchAccountUser?: boolean;
        fetchUserInfo?: boolean;
        fetchProfile?: boolean;
        fetchAccount?: boolean;
      };
};

/**
 * @description Fetches (and sets) user data from all Vuex stores. Note that this does not (yet) fetch the `account` field data on the Accounts Store. This is due to it mostly not being used outside of the Account and Onboarding Pages.
 *
 * @param overwrite - Determines whether the actions fetch and overwrite the data. Set to false (cache data from first action calls) by default.
 * @returns An object containing the results of the fetch actions.
 */
export const fetchUserData = async ({ overwrite = false }: FetchUserDataOptions) => {
  let overwriteFetchAccountUser: boolean;
  let overwriteFetchUserInfo: boolean;
  let overwriteFetchProfile: boolean;
  let overwriteFetchAccount: boolean;

  if (typeof overwrite === 'boolean') {
    overwriteFetchAccountUser = overwriteFetchUserInfo = overwriteFetchProfile = overwriteFetchAccount = overwrite;
  } else if (overwrite && typeof overwrite === 'object') {
    overwriteFetchAccountUser = overwrite.fetchAccountUser ?? false;
    overwriteFetchUserInfo = overwrite.fetchUserInfo ?? false;
    overwriteFetchProfile = overwrite.fetchProfile ?? false;
    overwriteFetchAccount = overwrite.fetchAccount ?? false;
  } else {
    overwriteFetchAccountUser = overwriteFetchUserInfo = overwriteFetchProfile = overwriteFetchAccount = false;
  }

  const [fetchAccountUser, fetchUserInfo, fetchProfile, fetchAccount] = await Promise.allSettled([
    AccountsStore.fetchAccountUser({ overwrite: overwriteFetchAccountUser }),
    UserInfoStore.fetchUserInfo({ overwrite: overwriteFetchUserInfo }),
    ProfilesStore.fetchProfile({ overwrite: overwriteFetchProfile }),
    AccountsStore.fetchAccount({ overwrite: overwriteFetchAccount }),
  ]);

  return {
    fetchAccountUser,
    fetchUserInfo,
    fetchProfile,
    fetchAccount,
  };
};
