import { NavigationGuard } from 'vue-router';

import { APPLY_INVITE, CREATOR_APPLICATION, APPLY_HOME, APPLY_ABOUT_YOU } from '@/utils/router/namedRoutes';

import Auth from '@rscollabs/rs-core-library/src/services/auth';

export const checkApplicationStatus: NavigationGuard = async function (to, from, next) {
  // If navigating directly to this path
  if (to.name === CREATOR_APPLICATION) {
    /**
     * New destination route name:
     * - Logged in goes to the APPLY_INVITE page
     * - A Referral ID or Sales Rep ID + Recruit Query params goes to the APPLY_ABOUT_YOU page
     * - Logged out goes to APPLY_HOME page
     */
    const name = Auth.isLoggedIn()
      ? APPLY_INVITE
      : to.query.refid || (to.query.sales_rep_id && to.query.recruit)
        ? APPLY_ABOUT_YOU
        : APPLY_HOME;

    return next({ name, query: to.query, replace: true });
  }

  return next();
};
